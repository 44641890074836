import React, {useState, useEffect} from "react";
import {usePDF, pdf, Page, Text, View, Document, StyleSheet , ReactPDF, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import ModelloPdf from './ModelloPdf';

const ScaricaPdf = (props) =>{
    console.log('scarico');
    const documento = props.documento;
    //genero il documento richiamano il modello pdf dal file ModelloPdf
    const MyDoc = ModelloPdf(props.documento);


    // creo il nome del file che verrà scaricato
    //const NomeFile = 'Fattura '+ props?.datiFattura.numero +' ('+ props.datiFattura?.data?.substring(0,4) + ') - ' + props?.datiCliente.Nome + ')';
    // creo il nome del file che verrà scaricato
    const NomeFile = documento.tipo + ' (' +
                    documento.datiFattura.numero + ' - ' +
                    documento.datiFattura.data.slice(-4) + ') - ' +
                    documento.datiCliente.cognome + ' ' +
                    documento.datiCliente.nome + '.pdf';
    return(
        <div className="pdf_btn_download">
            <img src={require("../img/pdf-file.png")} />
            <PDFDownloadLink document={MyDoc} fileName={NomeFile}>
                {({ blob, url, loading, error }) =>
                    loading ? 'Attendere' : 'Scarica PDF'
                }
            </PDFDownloadLink>
        </div>
    )
}

export default ScaricaPdf;