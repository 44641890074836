import React, {useState, useEffect} from "react";

function PredefinitaSalvataggio(props){

    const not = props.input[0];
    const richiesta = props.input[1];
    const token = props.input[2];

    const [form, setForm] =useState({
        titolo: 'titolo',
        testo: 'testo',
        url_script: ''
    })

    // riunisco in indica array i props documento e tipo
    const [bozza, setBozza] = useState(not);

    
    useEffect(()=>{
        if(richiesta === 'Nota_form'){
            setForm({...form,
                        titolo: 'Salva Nota',
                        testo: 'Puoi salvare la nota per renderla disponibili in futuro.',
                        url_script: './db_gestione/nota_inserimento.php'
                    })
        };
    
        if(richiesta === 'Email_form'){
            setForm({...form,
                        titolo: 'Bozza predefinita',
                        testo: 'Puoi salvare il testo della mail per renderlo disponibili in futuro.',
                        url_script: './db_gestione/mail_inserimento.php'
                    })
        };

        if(richiesta === 'Predefinito_form'){
            setForm({...form,
                        titolo: 'Documento predefinito',
                        testo: 'Puoi salvare questo modello di documento per poterlo riutilizzare in futuro.',
                        url_script: './db_gestione/documento_predefinito_salvataggio.php'
                    })
            setBozza(not[0]);
        };
    },[])

    const SalvaMail = () =>{
        console.log(token);
        const data = new FormData();
        data.append('bozza', JSON.stringify(bozza));  
        data.append('token', token);    
        var xhttp = new XMLHttpRequest();
    
        // Set POST method and ajax file path
        xhttp.open("POST", form.url_script, true);
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                 console.log(this.responseText);
              }
            }
         // Send request with data
         xhttp.send(data);
         
         // faccio sparire la finestra
         props.output(false);
    }

    useEffect(()=>{
        console.log(bozza)
    },[bozza]);

    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container">
            <div className="notaContainer">
                <h1>{form.titolo}</h1>
                <p>{form.testo}</p>

                <label style={{fontWeight:"bold", margin:"20px 0px 0px 0px"}}>Titolo di riferimento</label>
                <input type='text' className="input_form" value={bozza.titolo} onChange={(e)=>(setBozza({...bozza, titolo: e.target.value}))}></input>
                
                <div className="radio_container" >
                    <label style={{fontWeight:"bold"}}>Disponibile per ...</label>
                    <div style={{display:"flex", flex_direction:"row"}}>
                        <input type="radio" id="Fattura" name="Fattura" value="Fattura" checked={bozza.tipo === 'Fattura'? true : false} onChange={(e)=> setBozza({...bozza, tipo: e.target.value})}></input>
                        <label for="Fattura">Fattura</label>
                    </div>
                    <div style={{display:"flex", flex_direction:"row"}}>
                        <input type="radio" id="Preventivo" name="Preventivo" value="Preventivo" checked={bozza.tipo === 'Preventivo'? true : false} onChange={(e)=> setBozza({...bozza, tipo: e.target.value})}></input>
                        <label for="Preventivo">Preventivo</label>
                    </div>
                    <div style={{display:"flex", flex_direction:"row"}}>
                        <input type="radio" id="Entrambe" name="Entrambe" value="Entrambi" onChange={(e)=> setBozza({...bozza, tipo: e.target.value})}></input>
                        <label for="Preventivo">Entrambi</label>
                    </div>
                </div>
                </div>

                {
                    // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                }
                <button className='btn btn_ok' onClick={()=>SalvaMail()} disabled={bozza.titolo !== null && bozza.titolo !== '' ? false : true}>Ok</button>
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='btn btn_annula' onClick={()=>props.output(false)}>Annulla</button>
            </div>
        </div>
    )
}

export default PredefinitaSalvataggio;