import React, {useState, useEffect} from "react";



function Sconto(props){

    const [sconto, setSconto] = useState((props.input.valoreInput ? props.input.valoreInput : '0'));


    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container">
                <h1>Sconto</h1>
                <label className="label_input">Sconto sul totale</label>
                <input type='number'  className='input_number' onChange={(e)=>setSconto(e.target.value)} value={sconto}></input>
                
                
                {
                    // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                }
                <button className='btn btn_ok' onClick={()=>props.output(sconto, 'sconto', false)}>Ok</button>
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='btn btn_annulla' onClick={()=>props.output(null, null, false)}>Annulla</button>
            </div>
        </div>
    )
}
export default Sconto;