import React, {useState, useEffect} from "react";
import ScaricaPdf from "../Componenti/ScaricaPdf";
import {usePDF, pdf, Page, Text, View, Document, StyleSheet , ReactPDF, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import ModelloPdf from "../Componenti/ModelloPdf";
import './InviaCreaPdf.css';
import axios from 'axios';
import PredefinitaSalvataggio from "./PredefinitaSalvataggio";
import AlertBox from "../Componenti/AlertBox";
import Sconto from "./Sconto";
import { isDisabled } from "@testing-library/user-event/dist/utils";

function InviaCreaPdf(props){

    const dati = props.input.valoreInput;
    const token = props.input.token;

    const [inviaStato, setInviaStato] = useState(true);
    
    const documento = dati;
    
    const [indexMail, setIndexMail] = useState(0);

    const [totale,setTotale] = useState(0);

    // alertBox Invio in corso
    const invioInCorso = ({
        stato : true,
        identificativo: 'invio_in_corso',
        stile: 'Normale',
        titolo: 'Invio in corso...',
        testo : 'attendere.',
        opzione1 : 'OK',
        opzione2 : 'Annulla',
    })

    const invioRiuscito = ({
        stato : true,
        identificativo: 'invio_in_corso',
        stile: 'Riuscito',
        titolo: 'Invio riuscito',
        testo : 'Email inviata correttamente.',
        opzione1 : 'OK',
        opzione2 : 'Annulla',
    })

    const invioFallito = ({
        stato : true,
        identificativo: 'invio_in_corso',
        stile: 'Attenzione',
        titolo: 'Errore - Invio non riuscito',
        testo : 'Rieseguire accesso a gmail <a href="localhost/gestionale/get_oauth_token.php" target="_blank">Clicca qui</a> o inviare da www.GMAIL.com',
        opzione1 : 'OK',
        opzione2 : 'Annulla',
    })

    //tengo traccia dello stato di visualizzazione di 'MailPredefinitaSalvataggio'
    const [stato, setStato] = useState(false);

    const [bozzeList, setBozzeList] = useState([{
        corpo_mail: "Hello World",
        data_creazione: "2022-04-20 22:48:13",
        id: 1,
        oggetto: "RIENZI ELETTRONICA ANTIFURTI -- Documenti",
        tipo: "Preventivo",
        titolo: "dante"
    }]);

    // proprietà della mail
    const [email, setEmail] = useState({
        indirizzo: documento.datiCliente.email,
        nome: documento.datiCliente.cognome + ' ' + documento.datiCliente.nome,
        oggetto: 'RIENZI ELETTRONICA ANTIFURTI -- Documenti',
        corpoMail: 'Hello World',
    })

    // Recupero le bozze soltanto all'apertura della finestra
    useEffect(()=>{
        // setto indexMail in base alla tipologia di documento
        if(props.input.tipo == 'Fattura'){
            setIndexMail(props.input.preferenze.indexBozzaFattura);
        }
        if(props.input.tipo == 'Preventivo'){
            setIndexMail(props.input.preferenze.indexBozzaPreventivo);
        }
        // recupero le bozze delle mails
        RecuperoBozze()
        Somma();
    },[]);

    useEffect(()=>{
        ScelgoBozza(indexMail);    
    },[bozzeList]);

//#region  Bozze -----------------------------------------------------------------------------------------------------------------------
    
    const RecuperoBozze = () =>{
        var formData = new FormData();
        formData.append("tipo", documento.tipo);
        formData.append("token", token);
        var xhttp = new XMLHttpRequest();
    
        // Set POST method and ajax file path
        xhttp.open("POST", "./db_gestione/mail_recupero.php", true);
        // call on request changes state
        xhttp.onreadystatechange = function() {
           if (this.readyState == 4 && this.status == 200) {
            try{
             var response = JSON.parse(this.responseText);
             setBozzeList(response);
            }catch(error){console.log(error)}
           }
        };
        // Send request with data
        xhttp.send(formData);
    } 

    const ScelgoBozza = (index) => {
        const scelta = bozzeList.at(index);
        setEmail({...email, oggetto: scelta.oggetto, corpoMail: scelta.corpo_mail});
    }

//#endregion -----------------------------------------------------------------------------------------------------------------------

//#region PDF generatore -----------------------------------------------------------------------------------------------------------------------
    
    // genero il documento richiamando il modello pdf dal file ModelloPdf
    const MyDoc = ModelloPdf(documento);

    // genero all'interno di instance il blob del pdf preso da myDoc
    const [instance, updateInstance] = usePDF({ document: MyDoc });



    // creo il nome del file che verrà scaricato
    const NomeFile = documento.tipo + ' (' +
                    documento.datiFattura.numero + ' - ' +
                    documento.datiFattura.data.slice(-4) + ') - ' +
                    documento.datiCliente.cognome + ' ' +
                    documento.datiCliente.nome + '.pdf';
    
    // Trova la somma dei prodotti e servizi e sottrae lo sconto.
    const Somma = () =>{
        const calcoli = [];
        documento.prodottiServizi.map(element=>{
            const somma = element.prezzo * element.quantita;
            calcoli.push(somma);
        })
        const totaleNonScontato = calcoli.reduce((partialSum, a) => partialSum + a, 0);
        setTotale(totaleNonScontato - documento.sconto);
    }
    
//#endregion -----------------------------------------------------------------------------------------------------------------------

//#region InvioDocumento -----------------------------------------------------------------------------------------------------------------------

    const InvioDocumento = () => {

        //faccio apparire l'alertBox
        props.output(invioInCorso, 'invio_in_corso', true)

        // creo il file utilizzando la variabile instance
        const files = new File([instance.blob ], NomeFile,{ 
          type: "application/pdf",
          lastModified:new Date().getTime()
        });
    
        //"Allego" il file al formData e aggiungo indirizzo destinatario e il corpo della  mail
        var formData = new FormData();
        formData.append("file", files);
        formData.append("indirizzo", email.indirizzo);
        formData.append("nome", email.indirizzo);
        formData.append("oggetto", email.indirizzo);
        formData.append("corpo_mail", email.corpoMail);
        formData.append("token", token);
        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", "https://addonmail.000webhostapp.com/mailer/index.php", true);
        xhttp.onreadystatechange = function() {
           if (this.readyState == 4 && this.status == 200) {
            try{
             var response = this.responseText;
             console.log(response);
             // faccio apparire l'alert box adeguato
             if(response == 'Messaggio Inviato'){
                props.output(invioRiuscito, 'invio_in_corso', true)
             }else{
                props.output(invioFallito, 'invio_in_corso', true)
             }
            }catch(error){console.log(error)};
           }
        };
        xhttp.send(formData);
    }

//#endregion -----------------------------------------------------------------------------------------------------------------------

//#region HTML -----------------------------------------------------------------------------------------------------------------------
    return(
        
        <div className="Sfondo_blur">
            {stato === true ? <PredefinitaSalvataggio input={[email, 'Email_form',token]} output={(e)=>setStato(e)}></PredefinitaSalvataggio> : null }
            <div className="Finestra_container">
            <div className="notaContainer">
                <h1>Invia o crea PDF</h1>
                
                <div className="form_container">
                    <label className="label_input">Indirizzo Mail</label>
                    <input type='email' className='input_text' value={email.indirizzo} placeholder="esempio@host.it" onChange={(e)=>(setEmail({...email, indirizzo: e.target.value}))}></input>

                    <label className="label_input">Oggetto:</label>
                    <input type='text' className='input_text' value={email.oggetto} onChange={(e)=>(setEmail({...email, oggetto: e.target.value}))}></input>

                    <label className="label_input">Corpo Mail:</label>
                    <textarea type='text'  className='input_text areaNota' value={email.corpoMail} onChange={(e)=>(setEmail({...email, corpoMail: e.target.value}))}></textarea>
                    
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"center", marginBottom:"25px"}}>
                        <button type='button' className="btn_salva_mail_predefinito" onClick={()=>setStato(true)}><img src={require("../img/diskette.png")} />Aggiungi ai predefiniti</button>
                    </div>

                    <label className="label_input">Scegli predefinito</label>
                    <select className={'selectNota'}  style={{width:'100%'}}name="bozze" id="bozze" onChange={(e)=>ScelgoBozza(e.target.value)} defaultValue={indexMail}>
                        <option>-- Scegli --</option>
                        { bozzeList.map((i, index) => (
                            <option value={index} key={'optm'+index} >{i.titolo}</option>
                          ))
                        }
                    </select>

                    <div className="container_btns">
                        <button className={'btn_chiudi'} onClick={()=>props.output(null, null, false)}>Chiudi</button>
                        <ScaricaPdf documento={{...dati, totale:totale}}></ScaricaPdf>
                        <button className={'btn_invia_mail'} type='button' onClick={()=>InvioDocumento()} disabled={inviaStato}><img src={require("../img/email.png")}/>Invia Mail</button>
                        <BlobProvider document={MyDoc}>
                        {({ blob, url, loading, error }) => {
                            // Do whatever you need with blob here
                            console.log(blob);
                            blob !== null ? setInviaStato(false) : setInviaStato(true);
                        }}
                        </BlobProvider>
                    </div>
                </div>
                
                {
                    // Il pulsante annulla passa come output null, null false
                }
                
            </div>
            </div>
        </div>
    )
//#endregion -------------------------------------------------------------------------------------------------------------------------------------
}
export default InviaCreaPdf;