import React, {useState, useEffect} from "react";
import PredefinitaSalvataggio from "./PredefinitaSalvataggio";
import "./Nota.css";

function Nota(props){

    const dati = props.input.valoreInput;
    const token = props.input.token;

    const documento = dati;

    const [nota, setNota] = useState({
        tipo: documento.tipo,
        titolo: '',
        testo_nota: documento.nota,
    });

    const [stato, setStato] = useState(false);

    const [noteList, setNoteList] = useState([{
        tipo: 'Entrambe',
        titolo: '-- Scegli nota --',
        testo_nota: '',
    }]);

    const [statoEliminaBtn, setStatoEliminaBtn] = useState(false);

    useEffect(()=>{
        RecuperoNote()
    },[])

    const AlertBox = ({
        stato : true,
        identificativo: 'predefinito_eliminato',
        stile: '',
        titolo: 'Nota predefinita eliminata',
        testo : '',
        opzione1 : 'OK',
        opzione2 : 'Annulla',
    })
    
//#region  Bozze -----------------------------------------------------------------------------------------------------------------------
    
const EliminaNota = () =>{
    var formData = new FormData();
    formData.append("idNota", nota.id);
    formData.append("token", token);
    var xhttp = new XMLHttpRequest();

    // Set POST method and ajax file path
    xhttp.open("POST", "./db_gestione/nota_elimina.php", true);
    // call on request changes state
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
        props.output(AlertBox, 'predefinito_eliminato',true);
       }
    };
    // Send request with data
    xhttp.send(formData);
}

const RecuperoNote = () =>{
    var formData = new FormData();
    formData.append("tipo", documento.tipo);
    formData.append("token", token);
    var xhttp = new XMLHttpRequest();

    // Set POST method and ajax file path
    xhttp.open("POST", "./db_gestione/note_recupero.php", true);
    // call on request changes state
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
        try{
         var response = JSON.parse(this.responseText);
         setNoteList(response);
        }catch(error){
            console.log(error);
        }
       }
    };
    // Send request with data
    xhttp.send(formData);
} 

const ScelgoNota = (index) => {
    const scelta = noteList.at(index);
    setNota(scelta);

    // una volta scelta la nota predefinita attivo il btn per poterla eliminare
    setStatoEliminaBtn(true);
}

//#endregion -----------------------------------------------------------------------------------------------------------------------

    return(
        <div className="Sfondo_blur">
        {stato === true ? <PredefinitaSalvataggio input={[nota,'Nota_form',token]} output={(e)=>setStato(e)}></PredefinitaSalvataggio> : null }
            
            <div className="Finestra_container">
            <div className="notaContainer">
                <h1>Nota</h1>
                <label className="label_input">Nota da visualizzare</label>
                <textarea type='text'  className='input_text areaNota' onChange={(e)=>{setNota({...nota, testo_nota: e.target.value}); setStatoEliminaBtn(false)}} value={nota.testo_nota? nota.testo_nota : ''}></textarea>
                <button type='button' className="Btn_testo_predefinito btnAggiungi" onClick={()=>setStato(true)} disabled={nota.testo_nota !== null && nota.testo_nota !== '' ? false : true}><img src={require("../img/diskette.png")} />Aggiungi ai predefiniti</button>
                
                    <label className="label_input">Scegli predefinito</label>
                    
                    <div className="btnNoteContainer">
                        <select className={'selectNota'} name="bozze" id="bozze" onChange={(e)=>ScelgoNota(e.target.value)}>
                        <option> -- Scegli nota --</option>
                            { noteList.map((i, index) => (
                                <option value={index} key={'optN'+index}>{i.titolo}</option>
                            ))
                            }
                        </select>
                        <button type='button' className="Btn_testo_predefinito btnElimina" onClick={()=>EliminaNota()} disabled={statoEliminaBtn === true ? false : true}><img src={require("../img/cancel.png")} style={{width:'50%'}} /></button>
                    </div>
            </div>
                {
                    // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                }
                <button className='btn btn_ok' onClick={()=>props.output(nota.testo_nota, 'nota', false)}>Ok</button>
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='btn btn_annulla' onClick={()=>props.output(null, null, false)}>Annulla</button>
            </div>
        </div>
    )
}
export default Nota;