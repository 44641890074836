import './App.css';
import React, {useState, useEffect} from "react";
import axios from 'axios';
import Finestra from './Componenti/Finestra';
import Menu from './Componenti/Menu';
import AlertBox from './Componenti/AlertBox';
import Anteprima from './Componenti/Anteprima';
import { PDFViewer } from '@react-pdf/renderer';
import ModelloPdf from './Componenti/ModelloPdf';

function Applicazione(props) {

//#region Variabili ----------------------------------------------------------------------------------------------------------------------------------------------------------

  //#region Data odierna con formattazione gg/mm/aaaa
  var oggi = new Date();
  var dd = String(oggi.getDate()).padStart(2, '0');
  var mm = String(oggi.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = oggi.getFullYear();
  oggi = dd + '/' + mm + '/' + yyyy;
  //#endregion

  // contiene il token di autorizzazione da passare quando si richiedono i dati
  const token = props.token;

  // contiene il modello di DOCUMENTO vuoto
  const [base , setBase ]= useState({
    datiCliente: {
      id: null,
      nome: 'Nome' ,
      cognome: 'Cognome',
      indirizzo: 'Indirizzo',
      citta: 'Città',
      extra: '',
      email: null,
    },
    datiFattura: {
      numero: '1',
      data: oggi,
      citta: 'Palermo',
    },
    prodottiServizi: [],
    sconto: null,
    nota: null,
    tipo: 'Fattura',
    emessa: false,
    pdf: false,
    inviata: false,
    totale: 0,
  });

  const [totale, setTotale] = useState(0);

  // All'interno di questa variabile sono contenuti tutti i dati della fattura/preventivo
  const [documento, setDocumento] = useState(base)
    

  // Impostazioni dell'applicazione
  const [impostazioni, setImpostazioni] = useState({
    salva_btn_stato : true,
    emetti_btn_stato : true,
  });

  const [preferenze, setPreferenze] = useState({
    cittaAzienda: 'Palermo',
    indexBozzaFattura : 0,
    indexBozzaPreventivo : 0,
  })

  // Stato Visualizzazione Finestra
  const [finestra, setFinestra] = useState(false)
  const [daAprire, setDaAprire] = useState(null);
  const [valoreInput, setValoreInput] = useState(null);

  // AlertBox
  const [alertBox, setAlertBox] = useState({
    stato : false,
    identificativo: '',
    stile: '',
    titolo: 'Titolo',
    testo : 'Testo ...',
    opzione1 : 'OK',
    opzione2 : 'Annulla',
  })

  // Mostra / Nascondi Anteprima fattura se visualizzato da mobile
  const [anteprimaStato, setAnteprimaStato] = useState(true);

  const [dimensioniSchermo, setDimensioniSchermo] = useState(window.innerWidth);

//#endregion    

//#region Dimensioni Schermo -----------------------------------------------------------------------------------------------------------------
    
    const detectSize = () => {
        setDimensioniSchermo(window.innerWidth)
    }
    useEffect(()=>{
        window.addEventListener('resize', detectSize)
        if(dimensioniSchermo > 600){
          setAnteprimaStato(true);
        }
        if(dimensioniSchermo < 601){
            setAnteprimaStato(false)
        }
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    },[dimensioniSchermo])

//#endregion ----------------------------------------------------------------------------------------------------------------------------------

//#region UseEffect ----------------------------------------------------------------------------------------------------------------------------------------------------------
  
  useEffect(()=>{
    RecuperoTemporaneo();
    RecuperaNumeroProgressivo(documento.tipo);
    RecuperoPreferenze();
  },[])
  
  useEffect(()=>{
    setDocumento({...documento, datiFattura : base.datiFattura});
  },[base.datiFattura])

  useEffect(()=>{
    // se il documento è diverso rispetto al modello vuoto, allora salvo (senza questo if il docuemento viene salvato al primo rendering e quindi si avrà sempre un documento da ripristinare).
    if(JSON.stringify(documento) !== JSON.stringify(base)){
      SalvataggioTemporaneo();

      //Abilito il pulsante salva
      setImpostazioni({...impostazioni, salva_btn_stato: false});
      
      //Abilito il pulsante emetti solo se dati cliente e prodotti sono stati modificati
      if(JSON.stringify(documento.datiCliente) !== JSON.stringify(base.datiCliente) && 
        JSON.stringify(documento.prodottiServizi) !== JSON.stringify(base.prodottiServizi)){
          setImpostazioni({...impostazioni, emetti_btn_stato: false});
      }
      if(JSON.stringify(documento.datiCliente) === JSON.stringify(base.datiCliente) && 
        JSON.stringify(documento.prodottiServizi) === JSON.stringify(base.prodottiServizi)){
          setImpostazioni({...impostazioni, emetti_btn_stato: true});
      }     
    }
    console.log(documento);
    
  },[documento])

  // quando cambio da fattur a preventivo recupero il numero
  useEffect(()=>{
    RecuperaNumeroProgressivo(documento.tipo);
  },[documento.tipo])

  // Quando apro una finestra blocco lo scorrimento della pagina principale
  useEffect(()=>{
    if(finestra === true){

    }else{

    }
  },[finestra])

 //#endregion

//#region Operazioni ----------------------------------------------------------------------------------------------------------------------------------------------------------
  
  // Ordino i dati all'interno della variabile documento in base al tipo (datiCliente, fattura, note, sconto ecc..)
  const OrdinaDati = (dati, tipo) => {
    if(tipo === 'datiCliente'){
      setDocumento({...documento, datiCliente : dati});
    }
    if(tipo === 'sconto'){
      setDocumento({...documento, sconto : dati});
    }
    if(tipo === 'nota'){
      setDocumento({...documento, nota : dati});
    }
    if(tipo === 'prodottiServizi'){
      setDocumento({...documento, prodottiServizi : dati});
    }
    //----- tipo DOCUMENTO serve per aprie una fattura memorizzata
    if(tipo === 'documento'){
      setDocumento(dati);
    } 
    // Alert Box per invio mail
    if(tipo === 'invio_in_corso'){
      setAlertBox(dati);
    }
    // Impostazioni
    if(tipo === 'impostazioni'){
      setPreferenze(dati);
    }
    // Documento precompilato
    if(tipo === 'precompilato'){
      setDocumento({...documento, prodottiServizi : JSON.parse(dati.prodottiServizi), nota : dati.nota});
    }
    // Alert Box per eliminazione documento predefinito
    if(tipo === 'predefinito_eliminato'){
      setAlertBox(dati);
    }
  }

  // Richiamo questa funzione quando al click deve aprirsi una finestra
  const ClickScelta = (finestra, dati) => {
    setFinestra(true); 
    setDaAprire(finestra);
    setValoreInput(dati);
  }

  // Recupero l'ultimo numero documento emesso
  const RecuperaNumeroProgressivo  = (tipoDocumento) =>{
    
    const data = new FormData();
    data.append('tipoDocumento', tipoDocumento); // il tipo di documento Preventivo o fattura
    data.append('token', token);
    
    var xhttp = new XMLHttpRequest();

    xhttp.open("POST", './db_gestione/numero_prossimo.php', true);
    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        try{
          const nuovoNumeroDocumento = JSON.parse(this.responseText); 
          //Verifico che il numero sia > di 0 per evitare problemi con il savataggio
          if(nuovoNumeroDocumento.numero >= 0){
            // imposto la base (numero vecchia fattura + 1)
            const datFat = ({...base.datiFattura, numero : nuovoNumeroDocumento.numero + 1})
            setBase({...base, datiFattura: datFat});
          }
        }catch (errore) {
        }       
      }
    }
    // Send request with data
    xhttp.send(data);
  }

  // Aggiorno Totale
  const updateTotale = (tot) =>{
    // Trova la somma dei prodotti e servizi e sottrae lo sconto.
    const calcoli = [];
    documento.prodottiServizi.map(element=>{
        const somma = element.prezzo * element.quantita;
        calcoli.push(somma);
    })
    const totaleNonScontato = calcoli.reduce((partialSum, a) => partialSum + a, 0);
    console.log(totaleNonScontato);
    setTotale(totaleNonScontato);    
  

    setDocumento({...documento,totale:totale});
  }

//#endregion 

//#region Temporaneo ----------------------------------------------------------------------------------------------------------------------------------------------------------

  // Salvataggio Temporaneo
  const SalvataggioTemporaneo = () => {
      const data = new FormData();
      data.set('documento', JSON.stringify(documento))
      data.append('token', token);
      axios.post('./db_gestione/temporaneo_salvataggio.php', data);
  };

  // Recupero Temporaneo
  const RecuperoTemporaneo = () =>{
    const data = new FormData();
      data.append('token', token); // il tipo di documento Preventivo o fattura
      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", './db_gestione/temporaneo_recupero.php', true);
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          try{
      
            const result = JSON.parse(this.responseText);

            setDocumento(
              {
                datiCliente: JSON.parse(result['datiCliente']), 
                datiFattura: JSON.parse(result['datiFattura']), 
                prodottiServizi: JSON.parse(result['prodottiServizi']), 
                sconto: result['sconto'], 
                nota: result['nota'], 
                tipo: result['tipo'],
              })
            
              if(result){
              setAlertBox({
                stato : true,
                identificativo: 'temp_trovato',
                stile: '',
                titolo: 'Ripristina?',
                testo : 'è stato trovato un documento non salvato, vuoi ripristinarlo?',
                opzione1 : 'OK',
                opzione2 : 'Annulla',
              })
            }

          }catch (errore) {
            console.log(errore)
          }       
        }
      }
      // Send request with data
      xhttp.send(data);
  };

//#endregion

//#region Recupero Preferenze ------------------------------------------------------------------------------------------------------------------------------
  
  const RecuperoPreferenze = () =>{
    fetch('./db_gestione/preferenze_recupero.php').then(res => res.json()).then((result) => { 
      setPreferenze(result);
    },(error) => { 
        console.log(error); 
      })
  };

//#endregion

//#region Menu ----------------------------------------------------------------------------------------------------------------------------------------------------------

  const ScelteMenu = (output, tipo) =>{
    //Salvataggio documento su DB
    if(tipo === 'salvataggio'){
      setImpostazioni({...impostazioni, salva_btn_stato: output}); // disabilito il pulsante salva
      //se output rimane false(quindi non viene disabilitato) allora faccio apparire AlertBox
      if(output === false){
        setAlertBox({
                      stato : true, 
                      identificativo: 'Err_Salvataggio_Db',
                      stile : 'Attenzione',
                      titolo: 'Documento non salvato', 
                      testo: 'Qualcosa non ha funzionato durante il salvataggio, riprovare.',
                      opzione1: 'OK'  ,
                      opzione2: 'annulla'                    
                    });
      }
    }

    // Apertura da Db
    if(tipo === 'apertura da db'){
      ClickScelta('BrowserDocumenti', documento);
    }

    // Salvataggio offline(suFile)
    if(tipo === 'salvataggio offline'){
      var doc = JSON.stringify(documento);
      // salvo i parametri della fattura gia "stringati" sul file
      var FileSaver = require('file-saver');
      var blob = new Blob([doc], {type: "text/plain;charset=utf-8"});
      // creo il nome con cui verrà salvato il file es. 'Fattura (1 - 2022) Rienzi Raffaele.txt'
      const NomeFile =  documento.tipo + " (" + 
                        documento.datiFattura.numero + " - "+ 
                        documento.datiFattura.data.slice(0,4) + 
                        ") " +
                        documento.datiCliente.cognome + ' '+
                        documento.datiCliente.nome + 
                        ".txt";

      FileSaver.saveAs(blob, NomeFile);
    }

    // Apertura File locale
    if(tipo === 'apertura da file'){
      const reader = new FileReader();
      var text;
      reader.onload = (output) => {
        text = JSON.parse(output.target.result);
        //una volta "parsato" il contenuto del file, richiamo la funzione OrdinaDati per settare i valori in documento
        OrdinaDati(text, 'documento');
      };
      if(output.target.files[0]){
        reader.readAsText(output.target.files[0]);
      } 
    }

    // Apertura da Db
    if(tipo === 'nuovo documento'){
      // le operazioni vengono svolte in GestioneAlertBox()
      setAlertBox({
        stato : true,
        identificativo: 'nuovo_documento',
        stile: '',
        titolo: 'Nuovo documento?',
        testo : 'Vuoi creare un nuovo documento senza salvare quello corrente?',
        opzione1 : 'OK',
        opzione2 : 'Annulla',
      })
    }

    // Emetti documento
    if(tipo === 'emetti documento'){
      updateTotale();
      
      // controllo se il numero fattura corrispondente a quell'anno è già stato emesso, faccio apparire l'alert Adeguato.
      var formData = new FormData();
      formData.append("tipo", documento.tipo);
      formData.append("data", documento.datiFattura.data);
      formData.append("numero", documento.datiFattura.numero);
      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", "./db_gestione/controllo_doppia_emissione.php", true);
      xhttp.onreadystatechange = function() {
         if (this.readyState == 4 && this.status == 200) {
           var response = this.responseText;
           // faccio apparire l'alert box adeguato
           if(response === 'Numero libero'){
              // AlertBox numero documento disponibile
              setAlertBox({
                stato : true,
                identificativo: 'emetti_documento',
                stile: '',
                titolo: 'Emettere documento?',
                testo : 'Vuoi emettere il documento?',
                opzione1 : 'OK',
                opzione2 : 'Annulla',
              })
           }else{
              // AlertBox numero documento NON DISPONIBILE
              setAlertBox({
                stato : true,
                identificativo: 'emetti_documento',
                stile: 'Attenzione',
                titolo: 'Attenzione',
                testo : 'Il documento "'+ documento.tipo+'" numero '+documento.datiFattura.numero+' è già stato emesso il '+ response +'. Si consiglia di non continuare.',
                opzione1 : 'Continua',
                opzione2 : 'Annulla',
              })
           }
         }
      };
      xhttp.send(formData);
      
      setImpostazioni({...impostazioni, emetti_btn_stato: true});
    }

    // Impostazioni
    if(tipo === 'impostazioni'){
      ClickScelta('Impostazioni', preferenze);
    }

    // Precompilato
    if(tipo === 'precompilato'){
      ClickScelta('Precompilato', documento);
    }
  }

//#endregion

//#region ALertBox ----------------------------------------------------------------------------------------------------------------------------------------------------------

  const GestioneAlertBox=(opzione, identificativo)=>{
    // Errore salvatggio documento su db
    if(identificativo === 'Err_Salvataggio_Db'){
      if(opzione === 1){
        setAlertBox({...alertBox, stato:false});
      }
    }
    
    //Trovato documento in 'temporaneo'
    if(identificativo === 'temp_trovato'){
      if(opzione === 1){
        setAlertBox({...alertBox, stato:false});
      }
      if(opzione === 2){
        setAlertBox({...alertBox, stato:false});
        setDocumento(base);
        var xhttp = new XMLHttpRequest();
        // Set POST method and ajax file path
        xhttp.open("POST", "./db_gestione/temporaneo_svuota.php", true);
        xhttp.send();
      }
    }
    
    //Nuovo documento
    if(identificativo === 'nuovo_documento'){
      if(opzione === 1){
        RecuperaNumeroProgressivo(documento.tipo);
        setAlertBox({...alertBox, stato:false});
        setDocumento(base);
        var xhttp = new XMLHttpRequest();
        // Set POST method and ajax file path
        xhttp.open("POST", "./db_gestione/temporaneo_svuota.php", true);
        xhttp.send();
      }
      if(opzione === 2){
        setAlertBox({...alertBox, stato:false});
      }
    }

    //Nuovo documento
    if(identificativo === 'emetti_documento'){
      if(opzione === 1){
        
        setAlertBox({...alertBox, stato:false});

        const data = new FormData();
        data.set('documento', JSON.stringify(documento))
        axios.post('./db_gestione/documento_emissione.php', data);

        // dopo aver emesso il documento svuoto la memoria temporanea.
        var xhttp = new XMLHttpRequest();
        // Set POST method and ajax file path
        xhttp.open("POST", "./db_gestione/temporaneo_svuota.php", true);
        xhttp.send();

        const dati = ({...documento, preferenze: preferenze});
        ClickScelta('InviaCreaPdf', dati);
      }
      if(opzione === 2){
        setAlertBox({...alertBox, stato:false});
      }
    }

    // Alert Box per invio mail
    if(identificativo === 'invio_in_corso'){
      if(opzione === 1){
        setAlertBox({...alertBox, stato:false});
      }
      if(opzione === 2){
        setAlertBox({...alertBox, stato:false});
      }
    }

    // Alert Box per eliminazione documento predefinito
    if(identificativo === 'predefinito_eliminato'){
      if(opzione === 1){
        setAlertBox({...alertBox, stato:false});
      }
      if(opzione === 2){
        setAlertBox({...alertBox, stato:false});
      }
    }
    
  }

//#endregion

//#region HTML ----------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div style={finestra === true ?  {position: "fixed"} : {position: "unset"} }>

    { alertBox.stato === true ? <AlertBox proprieta={alertBox} output={(opzione, identificativo)=>(GestioneAlertBox(opzione, identificativo))} ></AlertBox> : null }

      <div className='Modalita_container'>
        <div className={documento.tipo === 'Fattura'? 'Opzione_modalita Selezionata' : 'Opzione_modalita'} onClick={()=>setDocumento({...documento, tipo : 'Fattura'})}>
          Fattura
        </div>

        <div className={documento.tipo === 'Preventivo'? 'Opzione_modalita Selezionata' : 'Opzione_modalita'} onClick={()=>setDocumento({...documento, tipo : 'Preventivo'})}>
          Preventivo
        </div>
      </div>
      
      <Menu impostazioni={impostazioni} output={(e, tipo)=>ScelteMenu(e,tipo)} input={[documento, base, totale]}></Menu>
      
      <div className='flexContainer'>

        <div className='Opzioni'>
          <div className='Area_btn' onClick={()=> ClickScelta('DatiCliente', documento.datiCliente)}>
            <h3>Dati clienti</h3>
          </div>

          <div className='Area_btn' onClick={()=> ClickScelta('ProdottiServizi', documento.prodottiServizi)} >
            <h3>Prodotti & Servizi</h3>
          </div>

          <div className='Area_btn' onClick={()=> ClickScelta('Sconto', documento.sconto)}>
            <h3>Sconto</h3>
          </div>

          <div className='Area_btn' onClick={()=> ClickScelta('Nota', documento)}>
            <h3>Nota</h3>
          </div>
          {
            dimensioniSchermo < 601? <div className='Area_btn' onClick={()=>  setAnteprimaStato(!anteprimaStato)}>
              {anteprimaStato? <h3>Nascondi Anteprima</h3> : <h3>Anteprima</h3>}
            </div> 
            : 
            null 
          }

        </div>
          {anteprimaStato? 
            <div className='Anteprima'>
              <Anteprima documento={documento} totale={(tot)=>updateTotale(tot)}/>   
            </div> 
            :
             null
          }

        <div className='Laterale'>
        </div>
      </div>

      {
        // daAprire = passo il nome della finestra da aprire -- input == passo dati che devono essere visualizzati nella finestra -- output = sono i dati da memorizzare, il tipo di dato e lo stato di visualizzazione
      }
      {finestra === true ? <Finestra daAprire={daAprire} input={{valoreInput,token}} output={(dati, tipo, stato)=>(OrdinaDati(dati, tipo),setFinestra(stato))}></Finestra> : null}

    </div>
  );
//#endregion
}

export default Applicazione;
