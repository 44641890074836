import React, {useState, useEffect} from "react";
import './Menu.css';
import axios from 'axios';
import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";

function Menu(props){
    const [statoMenu, setStatoMenu] = useState(false);
    const [dimensioniSchermo, setDimensioniSchermo] = useState(window.innerWidth);
    
//#region Dimensioni Schermo -----------------------------------------------------------------------------------------------------------------
    
    const detectSize = () => {
        setDimensioniSchermo(window.innerWidth)
    }
    useEffect(()=>{
        window.addEventListener('resize', detectSize)
        if(dimensioniSchermo > 600){
            console.log('maggiore')
            setStatoMenu(true);
        }
        if(dimensioniSchermo < 601){
            console.log('minore')
            setStatoMenu(false)
        }
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    },[dimensioniSchermo])

//#endregion ----------------------------------------------------------------------------------------------------------------------------------

    const Salva = () => {
        // il salvataggio avviene spostando il contenuto della tabella temporaneo -> su quella del documento di riferimento(fattura/preventivo).
        axios.post('./db_gestione/documento_salvataggio.php').then(response => { 

            // se la risposta è 'Salvato' , allora disattivo il pulsante salva
            if(response.data === 'Salvato'){
                props.output(true, 'salvataggio')
            }
            // se la risposta non è 'Salvato' , lascio il pulsante salva e lo segnalo all'utente
            if(response.data !== 'Salvato'){  
                props.output(false, 'salvataggio')
            }

          })
        .catch(error => {
                    if(error){
                        props.output(false, 'salvataggio')
                        console.log(error)
                    }
                }
        );
        
        // passo indietro  
        props.output(true, 'salvataggio')
    };

    const Apri = () => {
        // passo indietro  
        props.output(true, 'apertura da db')
    }

    const Impostazioni = () => {
        // passo indietro  
        props.output(true, 'impostazioni')
    }

    const SalvaOffline = () =>{
        props.output(true, 'salvataggio offline')
    }

    const AperturaFile = (e) =>{
        props.output(e, 'apertura da file')
    }
    
    const Nuovo = () =>{
        props.output(true, 'nuovo documento')
    }

    const Emetti = () =>{        
        props.output(true, 'emetti documento')
    }

    const Precompilato = () =>{
        props.output(true, 'precompilato')
    }
    
    const VociMenu = () =>{
        return(
            <div className="Menu_container">
                <button type='button' className="Menu_option_btn" onClick={()=>Nuovo()} ><img src={require("../img/page.png")} /><p className="Titoli_Menu">Nuovo</p></button>      
            {/* <button type='button' className="Menu_option_btn" onClick={()=>Salva()} >Salva</button> */}
                <button type='button' className="Menu_option_btn" onClick={()=>SalvaOffline()} ><img src={require("../img/diskette.png")} /><p className="Titoli_Menu">Salva(FILE)</p></button>  
                <div className="Menu_option_btn">
                <input type="file" className="custom-file-input" id="input" onChange={(e)=>AperturaFile(e)}></input>     
                </div>
                  
                <button type='button' className="Menu_option_btn" onClick={()=>Apri()} ><img src={require("../img/open-book.png")} /><p className="Titoli_Menu">Apri</p></button>      
                <button type='button' className="Menu_option_btn" onClick={()=>Impostazioni()} ><img src={require("../img/gear.png")} /><p className="Titoli_Menu">Impostazioni</p></button>        
                <button type='button' className="Menu_option_btn" onClick={()=>Precompilato()} ><img src={require("../img/heart.png")} /><p className="Titoli_Menu">Precompilato</p></button>     
                <button type='button' className="Menu_option_btn" onClick={()=>Emetti()} disabled={props.impostazioni.emetti_btn_stato}><img src={require("../img/invoice.png")} /><p className="Titoli_Menu">Emetti >></p></button>         
            </div>
            )
    }
    return(
        <div className="MostraNascondiContainer">
            <div className="Menu_btn" onClick={()=>setStatoMenu(!statoMenu)}>
                {dimensioniSchermo < 601 ? <h3 style={{marginRight: '10px'}}>{statoMenu? 'NASCONDI' : 'MENU'}</h3> : null}
                {dimensioniSchermo < 601 ? <img src={statoMenu? require('../img/cancel.png') : require('../img/menu.png') } /> : null}                
            </div>
            {statoMenu? VociMenu() : null}
            
        </div>
    )
}
export default Menu;
