import React, {useState, useEffect} from "react";
import {usePDF, pdf, Image,  Document, StyleSheet , ReactPDF, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import logo from '../img/logo.png';
import './Anteprima.css';

function Anteprima(props) {
  console.log(props.documento)
  const datiCliente = props.documento.datiCliente;
  const documento =  props.documento;
  const prodotti = documento.prodottiServizi;
  const [totale, setTotale] = useState(0);

  useEffect(()=>{
    Somma()
  },[])
  
  useEffect(()=>{
    Somma();
  },[prodotti])

  useEffect(()=>{
    props.totale(totale);
  },[totale]);

  // Trova la somma dei prodotti e servizi e sottrae lo sconto.
  const Somma = () =>{
    const calcoli = [];
    documento.prodottiServizi.map(element=>{
        const somma = element.prezzo * element.quantita;
        calcoli.push(somma);
    })
    const totaleNonScontato = calcoli.reduce((partialSum, a) => partialSum + a, 0);
    console.log(totaleNonScontato);
    setTotale(totaleNonScontato);    
  }

  return(
    <div className="Pagina">
      <div className={"intestazione"}>
        <div className="logoContainer">
          <img  className="logo" src={require("../img/logo.png")} />
        </div>

        <div className={'infoAziendaContainer'}>
          <p className={'nomeAzienda'}>RIENZI ELETTRONICA ANTIFURTI</p>
          <p className={'regular'}>Via Mogia, 3b - 90138 - Palermo</p>
          <p className={"bold"}>
          C.f.: <span className={'regular'}>RNZSVT55D24I533C -</span> P.Iva: <span className={'regular'}>05776350828</span><br></br>
          Cellulare: <span className={'regular'}>337891867</span><br></br>     
          Email: <span className={'regular'}>rienzielettronica@gmail.com</span><br></br>            
          Sito Web: <span className={'regular'}>rienzielettronic.altervista.org</span><br></br>
          </p>
        </div>
      </div>

      <div className={'datiFatturaContainer'}>
        <div className={'datiFattura'}>
          <p className={"bold"}>Palermo li: <span className={'regular'}>{documento.datiFattura.data}</span></p>
          <p className={"bold"}>{documento.tipo} numero: <span className={'regular'}>{documento.datiFattura.numero}({documento.datiFattura.data.slice(-4)})</span></p>
        </div>
        <div className={'datiCliente'}>
          <p className="bold">{datiCliente.cognome + ' ' + datiCliente.nome}</p>
          <p className="regular">{datiCliente.indirizzo + ' '+datiCliente.citta}</p>
          <p className="regular">{datiCliente.extra}</p>
        </div>
      </div>

      <div className="tabellaContainer">
        <div className="head">
          <div className="descrizione">Descrizione</div>
          <div className="quantita">Q.ttà</div>
          <div className="prezzoU">Unitario</div>
          <div className="prezzoT">Importo</div>
        </div>
        
        {
          prodotti.map((element, index)=> (
            <div className={Number.isInteger(index/2)? "row" : "lightrow"}>          
              <div className="descrizione cell">{element.descrizione}</div>
              <div className="quantita cell">{element.quantita}</div>
              <div className="prezzoU cell">{element.prezzo}</div>
              <div className="prezzoT cell">{element.quantita * element.prezzo}</div>
            </div>        
          ))
        }

        <div className="resoconto">
          <div className="resocontoDescrizioni">
            <p className="regular">Imponibile</p>
            <p className="regular">Iva 0% di {Number(totale) + Number(documento.sconto)}</p>
            <p className="regular">Sconto in fattura</p>
            <p className="bold">Totale EUR</p>
            <p className="regular">Fattura soggetta a bollo</p>
          </div>
          <div className="resocontoImporti">
            <p className="regular">{totale}</p>
            <p className="regular">0</p>
            <p className="regular">{documento.sconto > 0 ? documento.sconto : '0'}</p>
            <p className="bold">{Number(totale) - Number(documento.sconto)}</p>
            <p className="regular">2,00</p>
          </div>
        </div>

        
      </div>
      <div className="noteContainer"><span className="bold">Nota:</span> <p>{documento.nota}</p>
      </div>

      <div className="piePagina"><p className="bold">Operazione effettutata ai sensi dell'art.1 comma da 54 a 89 della Legge n.190/2014 - Regie forfettario Operazione in franchiga da Iva ai sensi della legge xx/xxxx.</p> 
      </div>
    </div>
  )
}
export default Anteprima;