import React, {useState, useEffect} from "react";

function MailPredefinitaSalvataggio(props){

    // riunisco in inica array i props documento e tipo
    const [bozza, setBozza] = useState({...props.input[0] , titolo: '', tipo: props.input[1]});

    const SalvaMail = () =>{
        const data = new FormData();
        data.append('bozza', JSON.stringify(bozza));    
        var xhttp = new XMLHttpRequest();
    
        // Set POST method and ajax file path
        xhttp.open("POST", "./db_gestione/mail_inserimento.php", true);
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                 console.log(this.responseText);
              }
            }
         // Send request with data
         xhttp.send(data);
         
         // faccio sparire la finestra
         props.output(false);
    }

    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container">
                <h1>Mail predefinita</h1>
                <p>Puoi salvare il corpo e l'oggetto della mail per renderli disponibili in futuro.</p>

                <label>Titolo di riferimento</label>
                <input type='text' className="input_form" value={bozza.titolo} onChange={(e)=>(setBozza({...bozza, titolo: e.target.value}))}></input>
                
                <div className="radio_container" >
                    <label>Disponibile per ...</label>
                    <input type="radio" id="Fattura" name="Fattura" value="Fattura" checked={bozza.tipo === 'Fattura'? 'true' : false} onChange={(e)=> setBozza({...bozza, tipo: e.target.value})}></input>
                    <label for="Fattura">Fattura</label>

                    <input type="radio" id="Preventivo" name="Preventivo" value="Preventivo" checked={bozza.tipo === 'Preventivo'? 'true' : false} onChange={(e)=> setBozza({...bozza, tipo: e.target.value})}></input>
                    <label for="Preventivo">Preventivo</label>

                    <input type="radio" id="Entrambe" name="Entrambe" value="Entrambi" checked={bozza.tipo === 'Entrambi'? 'true' : false} onChange={(e)=> setBozza({...bozza, tipo: e.target.value})}></input>
                    <label for="Preventivo">Entrambi</label>

                </div>

                {
                    // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                }
                <button className='conferma_btn' onClick={()=>SalvaMail()}>Ok</button>
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='annulla_btn' onClick={()=>props.output(false)}>Annulla</button>
            </div>
        </div>
    )
}
export default MailPredefinitaSalvataggio;