import React, {useState, useEffect} from "react";
import './Login.css';

function Login(props){

    const [credenziali, setCredenziali] = useState({
        username: '', //------------- da cambiare con vuoto
        password: '',
    });
    
    const [logBtnStato, setLogBtnStato] = useState(true);

    const [token, setToken] = useState('');

    const [messaggio, setMessaggio] = useState('');
    
    useEffect(()=>{
        if(credenziali.username !== '' && credenziali.password !== ''){
            setLogBtnStato(false);
        } else {
            setLogBtnStato(true);
        }
    },[credenziali])

    useEffect(()=>{        
        props.token(token)
    },[token])
    
    const Log =()=>{
        if(credenziali.username !== '' && credenziali.password !== ''){
            const data = new FormData();
            data.append('username', credenziali.username);
            data.append('password', credenziali.password); 
            var xhttp = new XMLHttpRequest();
            xhttp.open("POST", "./authentication/login.php", true);
            xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                    // quando il server risponde inserisco i dati all'interno di risultati
                    setToken(this.responseText);  
                    if(this.responseText === ''){
                        setMessaggio('Username o Password errati');
                    }                  
                }
            }
            // Send request with data
            xhttp.send(data);
        }
    }

    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container">
                <h1>Login</h1> 
                <label>Username</label>
                <input type="text" value={credenziali.username} onChange={e=>setCredenziali({...credenziali, username: e.target.value})}></input>          
                <label>Password</label>
                <input type="password" value={credenziali.password} onChange={e=>setCredenziali({...credenziali, password: e.target.value})}></input>       
                <button onClick={()=>Log()} disabled={logBtnStato}>Login</button>
                <h3>{messaggio}</h3>
            </div>
        </div>
    )
};

export default Login;