import React, {useState, useEffect} from "react";
import PredefinitaSalvataggio from "./PredefinitaSalvataggio";

function Precompilato(props){
    const dati = props.input.valoreInput;
    const token = props.input.token;

    const documento = dati;

    const [stato, setStato] = useState(false);

    const [documentiList, setDocumentiList] = useState([{
        tipo: 'Entrambe',
        titolo: '-- Scegli precompilato --',
        testo_nota: '',
    }]);

    const [predefinito, setPredefinito] = useState([{
        tipo: documento.tipo,
        prodottiServizi: documento.prodottiServizi,
        nota: documento.nota,
    }]);

    const [statoEliminaBtn, setStatoEliminaBtn] = useState(false);

    const AlertBox = ({
        stato : true,
        identificativo: 'predefinito_eliminato',
        stile: '',
        titolo: 'Predefinito eliminato',
        testo : '',
        opzione1 : 'OK',
        opzione2 : 'Annulla',
    })

    useEffect(()=>{
        RecuperoPredefiniti()
    },[])

//#region  Bozze -----------------------------------------------------------------------------------------------------------------------
    
const EliminaNota = () =>{
    var formData = new FormData();
    formData.append("idDocumento", predefinito.id);
    formData.append("token", token);
    var xhttp = new XMLHttpRequest();

    // Set POST method and ajax file path
    xhttp.open("POST", "./db_gestione/documento_predefinito_elimina.php", true);
    // call on request changes state
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
            props.output(AlertBox, 'predefinito_eliminato',true);
       }
    };
    // Send request with data
    xhttp.send(formData);
}

const RecuperoPredefiniti = () =>{
    var formData = new FormData();
    formData.append("tipo", documento.tipo);
    formData.append("token", token);
    var xhttp = new XMLHttpRequest();

    // Set POST method and ajax file path
    xhttp.open("POST", "./db_gestione/documento_predefinito_recupero.php", true);
    // call on request changes state
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
        try{
         var response = JSON.parse(this.responseText);
         setDocumentiList(response);
        }catch(error){console.log(error)};
       }
    };
    // Send request with data
    xhttp.send(formData);
} 

const ScelgoPredefinito = (index) => {
    const scelta = documentiList.at(index);
    setPredefinito(scelta);
    // una volta scelto il documento predefinito attivo il btn per poterla eliminare
    setStatoEliminaBtn(true);
}

//#endregion -----------------------------------------------------------------------------------------------------------------------

    return(
        <div className="Sfondo_blur">
        {stato === true ? <PredefinitaSalvataggio input={[predefinito,'Predefinito_form',token]} output={(e)=>setStato(e)}></PredefinitaSalvataggio> : null }
            
            <div className="Finestra_container">
            <div className="notaContainer">
                <h1>Precompilato</h1>
                
                <label className="label_input">Scegli modello</label>
                <div style={{display:"flex", flexDirection:"row", justifyContent: "space-evenly"}}>
                <select style={{height:"44px", marginBottom:"20px",}} className='selectNota' name="bozze" id="bozze" onChange={(e)=>ScelgoPredefinito(e.target.value)}>
                <option> -- Scegli precompilato --</option>
                    { documentiList.map((i, index) => (
                        <option value={index}>{i.titolo}</option>
                      ))
                    }
                </select>

                    

                        <button type='button' className="Btn_testo_predefinito btnElimina" onClick={()=>EliminaNota()} disabled={statoEliminaBtn === true ? false : true}><img src={require("../img/cancel.png")} style={{width:'50%'}} /></button>
                    
                        
                    </div><button style={{width:"180px", alignSelf:'center', border:"1px solid black", justifyContent:"center"}} type='button' className="btn Btn_testo_predefinito Menu_option_btn" onClick={()=>setStato(true)}><img src={require("../img/diskette.png")} /><p style={{marginLeft:"5px"}}>Salva Modello</p></button>
                </div>
                
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    {
                        // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                    }
                    <button className='btn btn_ok' onClick={()=>props.output(predefinito, 'precompilato', false)} disabled={predefinito.prodottiServizi !== null && predefinito.prodottiServizi !== undefined ? false : true}>Apri Modello</button>
                    {
                        // Il pulsante annulla passa come output null, null false
                    }
                    <button className='btn btn_annulla' onClick={()=>props.output(null, null, false)}>Annulla</button>
                </div>
            </div>
        </div>
    )
}
export default Precompilato;