import React, {useState, useEffect} from "react";
import {usePDF, pdf, Page, Text,Image, View, Document, StyleSheet , ReactPDF, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import logo from '../img/logo.png';

function ModelloPdf(props) {

  //const documento =  props.documento;
  const documento =  props;
  const prodotti = documento.prodottiServizi;
  const tot = props.sconto
  console.log(props);

  // 27 prodotti massimo

// Stile pdf
const styles = StyleSheet.create({
  
  page: { backgroundColor: 'white', 
          display: 'flex', 
          alignItems: 'center',
          padding:30,
          
        },

  section: { color: 'black', 
            textAlign: 'center',  
            flexDirection: 'row',           	
           	width: '100%',
           	},
  
  linea: {borderBottom: '1px solid black',},

  //#region Intestazione
  intestazione: {height: '15%',},
  logoContainer: {height:'85%',},
  logo: {
      height: 'auto',
      width: 'auto',
  },
  //#endregion

  //#region InfoFattura
  infoAziendaContainer: {width:'100%',textAlign:'right'},
  nomeAzienda: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '16px',
  },

  datiFatturaContainer : {height: '10%'},
  datiFattura : {width:'50%', textAlign:'left', paddingTop:'20px'},
  datiCliente : {width:'50%', textAlign:'right', paddingTop:'20px'},
  //#endregion

  //#region TABELLA PRODOTTI
  prodotti: {height:'auto', paddingTop:'20px'},
  table:{width: '100%', flexDirection: 'column', color: 'black', textAlign: 'center', fontFamily:'Helvetica', fontSize:'12px'},
  head: {flexDirection: 'row', backgroundColor: 'white',},
  row:	{flexDirection: 'row', backgroundColor: 'rgba(240,240,240,1)', borderBottom: '1px solid black'},
  lightrow:{flexDirection: 'row', backgroundColor: 'white', borderBottom: '1px solid black' },
  //cell: {border: '1px solid black', fontWeight: 'bold',},
  
  // Larghezza colonne prodotto
  quantita: {width: '10%'},
  descrizione: {width: '60%',textAlign:'left'},
  prezzoU: {width: '15%'},
  prezzoT: {width: '15%'},

  // Resoconti  
  resoconto : {width: '100%', flexDirection: 'row',},
  vuoto : {width: '60%',flexDirection: 'row'},
  resocontoDescrizioni: {width:'30%', textAlign:'left'},
  resocontoImporti : {width:'10%', textAlign:'right'},
  //#endregion

  noteContainer: {height: '11%',width:'100%', position:'absolute', bottom:'20px', textAlign:'left', },

  pieDiPagina:{height: '7%', position:'absolute', bottom:'0px', width:'100%', borderTop:'1px solid black',marginBottom: '0px', textAlign:'center'},

  regular:{
    fontFamily: 'Helvetica',
    fontSize: '12px',
  },
  bold:{
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
  },
});
 
// Struttura PDF
const MyDoc = () => (
  <Document>
    <Page size="A4" style={styles.page} >
      
      <View style={[styles.section, styles.intestazione, styles.linea]}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src={logo} />
        </View>
        <View style={styles.infoAziendaContainer}>
          <Text style={styles.nomeAzienda}>RIENZI SALVATORE ELETTRONICA ANTIFURTI</Text>
          <Text style={styles.regular}>Via Mogia, 3b - 90138 - Palermo</Text>
          <Text style={styles.bold}>C.f.: <Text style={styles.regular}>RNZSVT55D24I533C -</Text> P.Iva: <Text style={styles.regular}>05776350828</Text></Text>
          <Text style={styles.bold}>Cellulare: <Text style={styles.regular}>337891867</Text></Text>
          <Text style={styles.bold}>Email: <Text style={styles.regular}>rienzielettronica@gmail.com</Text></Text>
          <Text style={styles.bold}>Sito Web: <Text style={styles.regular}>rienzielettronic.altervista.org</Text></Text>
        </View>
      </View>

      <View style={[styles.section, styles.datiFatturaContainer, styles.linea]}>
        <View style={styles.datiFattura}>
        	<Text style={styles.bold}>{documento.datiFattura.citta}: <Text style={styles.regular}>{documento.datiFattura.data}</Text></Text>
      	  <Text style={styles.bold}>{documento.tipo} numero: <Text style={styles.regular}>{documento.datiFattura.numero} ({documento.datiFattura.data.slice(-4)}) </Text></Text>
          </View>
        <View style={styles.datiCliente}>
          <Text style={styles.bold}>{documento.datiCliente.nome + ' ' + documento.datiCliente.cognome}</Text>
          <Text style={styles.regular}>{documento.datiCliente.indirizzo}</Text>
          <Text style={styles.regular}>{documento.datiCliente.citta}</Text>
          <Text style={styles.regular}>{documento.datiCliente.extra}</Text>
      	</View>
      </View>

      <View style={[styles.table, styles.prodotti]}>        
        <View style={[styles.head, styles.bold]}>          
          <View style={[styles.cell, styles.descrizione]}>
              <Text>Descrizione</Text>
          </View>
          <View style={[styles.cell, styles.quantita]}>
              <Text>Q.ttà</Text>
          </View>
          <View style={[styles.cell, styles.prezzoU]}>
              <Text>Unitario</Text>
          </View>
          <View style={[styles.cell, styles.prezzoT]}>
              <Text>Importo</Text>
          </View>
        </View>
        {
          prodotti.map((element, index)=> (
            <View style={Number.isInteger(index/2)? styles.row : styles.lightrow}>          
              <View style={[styles.cell, styles.descrizione]}>
                  <Text>{element.descrizione.toUpperCase()}</Text>
              </View>
              <View style={[styles.cell, styles.quantita]}>
                  <Text>{element.quantita}</Text>
              </View>
              <View style={[styles.cell, styles.prezzoU]}>
                  <Text>{/*element.prezzo*/}</Text>
              </View>
              <View style={[styles.cell, styles.prezzoT]}>
                  <Text>{/*element.prezzo * element.quantita*/}</Text>
              </View>
            </View>        
          ))
        }
        
        <View style={styles.resoconto}>
          <View style={styles.vuoto}></View>
          <View style={[styles.resocontoDescrizioni, styles.linea]}>
            <Text style={styles.regular}>Imponibile</Text>
            <Text style={styles.regular}>Iva 0% di {Number(documento.sconto) < 0 ? Number(documento.totale) : Number(documento.totale) + Number(documento.sconto)}</Text>
            <Text style={styles.regular}>Sconto {documento.tipo === 'Fattura'? 'in fattura':null}</Text>
            <Text style={styles.bold}>Totale EUR</Text>
            <Text style={styles.regular}>Fattura soggetta a bollo</Text>
          </View>
          <View style={[styles.resocontoImporti, styles.linea]}>
            <Text style={styles.regular}>{Number(documento.sconto) < 0 ? Number(documento.totale) : Number(documento.totale) + Number(documento.sconto)}</Text>
            <Text style={styles.regular}>0,00</Text>
            <Text style={styles.regular}>{Number(documento.sconto) < 0 ? '0,00' : Number(documento.sconto)}</Text>
            <Text style={styles.bold}>{documento.totale}</Text>
            <Text style={styles.regular}>2,00</Text>
          </View>          
        </View>
        <View style={styles.resoconto}>
          <View style={styles.vuoto}></View>
          <View style={[styles.resocontoDescrizioni]}>
            <Text style={styles.regular}>Importo pagato</Text>
            <Text style={styles.bold}>Da pagare: (EUR)</Text>
          </View>
          <View style={[styles.resocontoImporti]}>
            <Text style={styles.regular}>0,00</Text>
            <Text style={styles.bold}>{documento.totale}</Text>
          </View>          
        </View>
      </View>

      <View style={[styles.section]}>
        <Text style={styles.regular}>Nota: {documento.nota}</Text>
      </View>

      <View style={[styles.section, styles.pieDiPagina]}>
        <Text style={styles.regular}>{documento.tipo === 'Fattura' ? "Operazione in franchigia da art 1.cc 54-89 L. 190/2014, non soggetta a ritenuta d'acconto ai sensi del c.67 legge 190/2014." : "Grazie per la preferenza accordataci."}</Text>
      </View>

      
    </Page>
  </Document>
);

return(
    MyDoc()
)
}
export default ModelloPdf;