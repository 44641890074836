import React, {useState, useEffect} from "react";
import './AlertBox.css';

function AlertBox(props){
/* let i = 1;
        let btn = [];
        Object.keys(props.proprieta).forEach(key => {
            if(key === 'opzione'+i){
                btn = [btn, ]
                i = i+1;
            }
        });
        
        return(
            {btn}
        )
    } */

    return(
        <div className="Alert_container_blur">
            <div className={"Alert_container " + props.proprieta.stile}>
                <h3>{props.proprieta.titolo}</h3>           
                <div dangerouslySetInnerHTML={{ __html: props.proprieta.testo }}></div>
                <div className="container_btn">
                    <button className='btn btn_ok' key='conferma' onClick={()=>props.output(1, props.proprieta.identificativo)}>{props.proprieta.opzione1}</button>
                    <button className='btn btn_annulla' key='annulla' onClick={()=>props.output(2, props.proprieta.identificativo)}>annulla</button>
                </div>   
            </div>
        </div>
    )
}
export default AlertBox;