import React, {useState, useEffect} from "react";
import './ProdottiServizi.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function prodottiServizi(props){

//#region Variabili --------------------------------------------------------------------------------------------------------------------------------
    const dati = props.input.valoreInput;
    const token = props.input.token;

    const base = ({
        descrizione: '',
        prezzo: 0.00,
        quantita: 1
    })

    const [prodotto, setProdotto] = useState(base);

    const [listaAggiunti, aggiornaLista] = useState(dati);

    const [salvaBottoneStato, setSalvaBottoneStato] = useState(true);

    // uso questa variabile per evitare che al primo render venga salvato il documento in temporaneo.
    const [primoRender, setPrimoRender] = useState(true);

    // Mantiene la somma totale della fattura
    const [totale, setTotale ] = useState(0);

    // in base al suo valore, viene visualizzato il pulsante 'Aggiungi prodotto' oppure 'Modifica prodotto'
    const [indexDaModificare, setIndexDaModificare] = useState(null);

//#endregion

//#region Barra di Ricerca ----------------------------------------------------------------------------------------------------------------

    // la lista prodotti da mostrare nella barra di ricerca
    const [items, setItems] = useState([]);

    // recupero la lista prodotti dal db soltanto quando viene caricata la pagina
    useEffect(() => {
      console.log('hi');
      const data = new FormData();
      data.append('token', token); // il tipo di documento Preventivo o fattura
      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", './db_gestione/prodotti_recupero.php', true);
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          try{
            if(this.responseText === 'Non sei loggato'){
              console.log(this.responseText);
            }else{
              setItems(JSON.parse(this.responseText));
            }
          }catch (errore) {
            console.log(errore)
          }       
        }
      }
      // Send request with data
      xhttp.send(data);
    }, []);

    // Ad ogni cambiameno nella barra di ricerca aggiorno il valore 'descrizione'
    const handleOnSearch = (string, results) => {
        setProdotto({...prodotto, descrizione: string})
      }
  
    // Quando viene selezionato un risultato, item contiene tutti i valori ad esso associato
    // Una volta selezionato si inseriscono questi valori all'interno di prodotto
    const handleOnSelect = (item) => {
      // quando seleziono una voce dal menu, cerco il prezzo medio è successivamente lo inserisco in prodotto insieme alla descrizione 
      var formData = new FormData();
      formData.append("prodotto", item.descrizione);
      formData.append("token", token);
      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", "./db_gestione/prodotti_prezzo_medio.php", true);
      xhttp.onreadystatechange = function() {
         if (this.readyState == 4 && this.status == 200) {
          setProdotto({...prodotto, descrizione: item['descrizione'].toUpperCase(), prezzo: parseFloat(this.responseText).toFixed(2)});
         }
      };
      xhttp.send(formData);
    }
    
    // Vengono creati i risultati da mostrare durante la ricerca  
    const formatResult = (item) => {
      return (
        <div>
          <span key={item.descrizione} style={{ display: 'block', textAlign: 'left' }}>{item.descrizione}</span>
        </div>
      )
    }
      
  //#endregion
 
//#region Gestione Prodotto -------------------------------------------------------------------------------------------------------------------------
    
    // quando viene cliccato il pulsante aggiungi prodotto
    const AggiungiProdotto = () =>{
        // se i dati dentro 'prodotto' sono cambiati, allora aggiorno la lista
        if(JSON.stringify(prodotto) !== JSON.stringify(base)){
            aggiornaLista(listaAggiunti => [...listaAggiunti, prodotto] );
            // resetto i campi
            setProdotto(base);
        }

        Salva();
    }

    const ModificaProdotto = () => {
        let newArr = [...listaAggiunti]; // copio la lista in una nuova array
        newArr[indexDaModificare] = prodotto; // modifico il prodotto al dato index
        aggiornaLista(newArr); // inserisco tutto dento la lista
        setIndexDaModificare(null); // setto indexDaModificare 'null' per far riapparire il tasto aggiungi
        setProdotto(base)  // azzero i campi input
    }

    // quando viene cliccato elimina su una scheda prodotto
    const RimuovoProdotto = (indice) =>{
        aggiornaLista(listaAggiunti.filter((item, index) => index !== indice))
    }

    // quando viene cliccata la scheda prodotto riporto i dati nel form di input
    const IniziaModifica = (index) =>{
        setProdotto(listaAggiunti[index]);
        setIndexDaModificare(index);
    }

    // verifico se prodotto è stato modificato e quindi ATTIVO o DISATTIVO IL PULSANTE di aggiunzione prodotti
    useEffect(()=>{
        if(JSON.stringify(prodotto) !== JSON.stringify(base)){
            setSalvaBottoneStato(false)
        };         
        if(JSON.stringify(prodotto) === JSON.stringify(base)){
            setSalvaBottoneStato(true)
        } 
    },[prodotto]);
    

    // quando la lista prodotti viene modificata passo i dati ad App per salvarli in 'temporanea' e ricalcolo il TOTALE
    useEffect(()=>{
        if(primoRender === false){
            props.output(listaAggiunti,'prodottiServizi',true)
        }
        Somma();
       //console.log(listaAggiunti);
    },[listaAggiunti])


    useEffect(()=>{
        // dopo il primo render imposto a false, serve per evitare che a primo render venga salvato un record in temporaneo
        setPrimoRender(false);
    },[]);

    // Salvo il prodotto nel database in 'prodotti'
    const Salva = () =>{
        const data = new FormData();
        data.append('descrizione', prodotto.descrizione);    
        data.append('token', token); 
        var xhttp = new XMLHttpRequest();
    
        // Set POST method and ajax file path
        xhttp.open("POST", "./db_gestione/prodotti_inserimento.php", true);
        xhttp.send(data);
    }

//#endregion

//#region Calcoli ------------------------------------------------------------------------------------------------------------------------------

    const Somma = () =>{
        // imposto 'totale' con la funzione reduce (reduce Javascript)
        setTotale(listaAggiunti.reduce((n, {prezzo, quantita}) => n + prezzo*quantita, 0));
    }

//#endregion

//#region Prezzo Medio ----------------------------------------------------------------------------------------------------------------------

    const PrezzoMedio = () =>{
        var formData = new FormData();
        formData.append("prodotto", prodotto.descrizione);
        formData.append("token", token);
        
        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", "./db_gestione/prodotti_prezzo_medio.php", true);
        xhttp.onreadystatechange = function() {
           if (this.readyState == 4 && this.status == 200) {
            setProdotto({...prodotto, prezzo: parseFloat(this.responseText).toFixed(2)});
           }
        };
        xhttp.send(formData);
    }
//#endregion --------------------------------------------------------------------------------------------------------------------------------


//#region HTML ------------------------------------------------------------------------------------------------------------------------------
    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container">
                
                <h1>Prodotti & Servizi</h1>
                
                <div className="container_forms">
                    <div className="form">
                        <label className="label_input">Descrizione</label>
                        <div style={{marginBottom: '10px'}}>
                            <ReactSearchAutocomplete
                                items={items}
                                onSearch={handleOnSearch}
                                onSelect={handleOnSelect}
                                autoFocus
                                formatResult={formatResult}
                                fuseOptions={{ keys: ["descrizione"] }}
                                resultStringKeyName="descrizione"
                                inputSearchString={prodotto.descrizione}
                                showIcon={true}
                                styling={{
                                    borderRadius: "0px",
                                    textTransform: "uppercase"
                                }}
                            />
                        </div>
                        
                        <div className="label_e_input">
                            <label className="label_input lbl1">Quantità: </label>
                            <div className="inpt2_container">
                                <input type='number' inputMode="numeric" className='input_text inpt1' onChange={(e)=>setProdotto({...prodotto, quantita: e.target.value})} value={prodotto.quantita}></input>
                            </div>
                        </div>

                        <div className="label_e_input">
                            <label className="label_input lbl1">Prezzo unitario: </label>                    
                            
                            <div className="inpt2_container">
                                <input type='number' inputMode="decimal" className='input_text inpt2' id='prezzo' onChange={(e)=>setProdotto({...prodotto, prezzo: e.target.value})} value={prodotto.prezzo}></input>
                                <button type='button' className="prezzo_medio_btn" onClick={()=>PrezzoMedio()}><img src={require("../img/stats.png")} /></button>
                            </div>
                        </div>
                        
                        <div className="label_e_input">
                            <label className="label_input lbl1">Costo totale: €</label> 
                            <div className="totale_container">
                            {prodotto.prezzo * prodotto.quantita}
                            </div>
                        </div>
        
                        {
                            indexDaModificare === null ? 
                                <button type='button' className="Btn_Aggiungi_prodotto btn_ok" onClick={()=>AggiungiProdotto()} disabled={salvaBottoneStato}>Aggiungi prodotto</button>
                                :
                                <button type='button' className="Btn_Modifica_prodotto btn_modifica" onClick={()=>ModificaProdotto()} >Modifica prodotto</button>
                        }
                        
                    </div>
                    
                    <div className="form">
                        <div className="Container_prodotti">
                            {
                                listaAggiunti.map((i,index) => (
                                    <div key={'a'+index} className="Scheda_prodotto" >
                                        <div key={'b'+index} className="scheda_quantita">
                                            <h1 className="Quantita">{i.quantita}</h1>
                                        </div>

                                        <div key={'c'+index} className='scheda_descrizione_container' onClick={()=>IniziaModifica(index)}>
                                            <h2  className="Descrizione">{i.descrizione}</h2>
                                            <p className="Prezzo_unitario">Prezzo unitario: € {i.prezzo}</p>
                                        </div>

                                        <div key={'d'+index} className="scheda_prezzo_elimina">
                                            <img src={require("../img/remove.png")} className="tag_elimina" onClick={()=>RimuovoProdotto(index)}/>
                                            <p className="Prezzo_totale">€ {parseFloat(i.prezzo * i.quantita).toFixed(2)}</p>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        
                        <div className="Container_totale">
                            <h3 className="totale_testo">Totale € {totale.toFixed(2)}</h3>
                        </div>
                        <div className="indietro_container">
                            <button  onClick={()=>props.output(null, null, false)}>Indietro</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )

//#endregion
}


export default prodottiServizi;