import React, {useState, useEffect} from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import '../Componenti/Finestra.css';

function DatiCliente(props){

    const dati = props.input.valoreInput;
    const token = props.input.token;

    const [datiCliente, setDatiCliente] = useState({
        id : (dati.id ? dati.id : ''),
        nome: (dati.nome ? dati.nome !== 'Nome' ? dati.nome : '' : ''),
        cognome: (dati.cognome ? dati.cognome !== 'Cognome' ? dati.cognome : '' : ''),
        indirizzo: (dati.indirizzo? dati.indirizzo !== 'Indirizzo'? dati.indirizzo : '' : ''),
        citta: (dati.citta? dati.citta !== 'Città' ? dati.citta : '' : ''),
        extra: (dati.extra? dati.extra : ''),
        email: (dati.email? dati.email : ''),
    });

    // la lista clienti da mostrare nella barra di ricerca
    const [items, setItems] = useState([]);

    // se il cliente è stato salvato viene modificato in true
    const [isSaved, setIsSaved] = useState(false);

    // recupero la lista clienti dal db soltanto quando viene caricata la pagina
    useEffect(() => {        
      const data = new FormData();
      data.append('token', token); // il tipo di documento Preventivo o fattura

      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", './db_gestione/clienti_recupero.php', true);
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          try{
            if(this.responseText === 'Non sei loggato'){
              console.log(this.responseText);
            }else{
              setItems(JSON.parse(this.responseText));
            }
          }catch (errore) {
            console.log(errore)
          }       
        }
      }
      // Send request with data
      xhttp.send(data);
    }, []);

//#region Barra di Ricerca

    // Ad ogni cambiameno nella barra di ricerca aggiorno il valore Nome
    const handleOnSearch = (string, results) => {
      setDatiCliente({...datiCliente, nome: string})
    }

    // Quando viene selezionato un risultato, item contiene tutti i valori ad esso associato,(es. nome ,cognome, indirizzo ecc..)
    // Una volta selezionato si inseriscono questi valori all'interno di datiCliente
    const handleOnSelect = (item) => {
      setDatiCliente(item)
    }

    // Vengono creati i risultati da mostrare durante la ricerca  
    const formatResult = (item) => {
      return (
        <div>
          <span style={{ display: 'block', textAlign: 'left' }}>nome: {item.nome}</span>
          <span style={{ display: 'block', textAlign: 'left' }}>indirizzo: {item.indirizzo}</span>
        </div>
      )
    }
    
//#endregion
  

  const Salva = () =>{
    const data = new FormData();
    data.append('nome', datiCliente.nome);
    data.append('cognome', datiCliente.cognome);
    data.append('indirizzo', datiCliente.indirizzo);
    data.append('citta', datiCliente.citta);
    data.append('extra', datiCliente.extra);
    data.append('email',datiCliente.email)
    data.append('token',token)

    var xhttp = new XMLHttpRequest();

    // Set POST method and ajax file path
    xhttp.open("POST", "./db_gestione/clienti_inserimento.php", true);
    // call on request changes state
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
            // quando il server risponde inserisco i dati all'interno di datiCliente, e imposto il saved su true per far chiudere la finestra.
            setDatiCliente({...datiCliente, id: this.responseText});
            setIsSaved(true);
         }
       }
    // Send request with data
    xhttp.send(data);
  }

  // Faccio chiudere la finestra e passo i dati quando isSavedè true.
  useEffect(()=>{
      if(isSaved){
        props.output(datiCliente, 'datiCliente', false);
      }
    },[isSaved])

//#region HTML
    return(
        //<div className="Sfondo_blur">
            <div className="Finestra_container">
                <h1>DatiCliente</h1>
                
                <label className="label_input">Nome</label>
                <div style={{marginBottom: '10px'}}>
                  <ReactSearchAutocomplete
                      items={items}
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelect}
                      autoFocus
                      formatResult={formatResult}
                      fuseOptions={{ keys: ["nome", "cognome"] }}
                      resultStringKeyName="nome"
                      inputSearchString={datiCliente.nome}
                      showIcon={true}
                      styling={{
                        borderRadius: "0px",
                      }}
                  />
                </div>
                <label className="label_input">Cognome</label>
                <input type='text'  className='input_text' onChange={(e)=>setDatiCliente({...datiCliente, cognome: e.target.value})} value={datiCliente.cognome}></input>
                
                <label className="label_input">Indirizzo</label>
                <input type='text'  className='input_text' onChange={(e)=>setDatiCliente({...datiCliente, indirizzo: e.target.value})} value={datiCliente.indirizzo}></input>
                
                <label className="label_input">Città</label>
                <input type='text'  className='input_text' onChange={(e)=>setDatiCliente({...datiCliente, citta: e.target.value})} value={datiCliente.citta}></input>
                
                <label className="label_input">Extra</label>
                <input type='text'  className='input_text' onChange={(e)=>setDatiCliente({...datiCliente, extra: e.target.value})} value={datiCliente.extra}></input>
                
                <label className="label_input">Email</label>
                <input type='email'  className='input_text' onChange={(e)=>setDatiCliente({...datiCliente, email: e.target.value})} value={datiCliente.email}></input>
                
                {
                    // datiCliente restituisce la collezione di dati, 'Cliente' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                }
                <button className='btn btn_ok' onClick={()=>(Salva())}>Ok</button>
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='btn btn_annulla' onClick={()=>props.output(null, null, false)}>Annulla</button>
            </div>
        //</div>
    )
//#endregion
}
export default DatiCliente;