import React, {useState, useEffect} from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import './BrowserDocumenti.css'

function BrowserDocumenti(props){

//#region Variabili --------------------------------------------------------------------------------------------

const dati = props.input.valoreInput;
const token = props.input.token;

const base = [{
    tipo: "",
    nome: "",
    cognome: "",
    indirizzo: "",
    datiCliente: {
        descrizione: '',
        prezzo: 0,
        quantita: 1
    },
    datiFattura: {
        numero: '',
        data: '',
        citta: '',
    },
    totale : null,
    prodottiServizi: [],
    sconto: "",
    nota: "",
    emessa : false,
    pdf: false,
    inviata: false,
    versione: 0,
}]

// la lista clienti da mostrare nella barra di ricerca
const [items, setItems] = useState([]);

const [risultati, setRisultati] = useState(base);

const [anni, setAnni] = useState({});

//#endregion ---------------------------------------------------------------------------------------------------

//#region UseEffect ----------------------------------------------------------------------------------------------------
    
    // recupero la lista clienti dal db soltanto quando viene caricata la pagina
    useEffect(() => {
        const data = new FormData();
        data.append('token', token); // il tipo di documento Preventivo o fattura
        
        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", './db_gestione/clienti_recupero.php', true);
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
            try{
                if(this.responseText === 'Non sei loggato'){
                console.log(this.responseText);
                }else{
                setItems(JSON.parse(this.responseText));
                }
            }catch (errore) {
                console.log(errore)
            }       
            }
        }
        // Send request with data
        xhttp.send(data);
        
        // all'apertura della finestra recupero gli anni in cui sono memorizzate le fatture.
        RecuperoAnni();

    }, []);

    useEffect(()=>{
        Cerca(anni[0],'anno')
    },[anni])
    

//#endregion ---------------------------------------------------------------------------------------------------

//#region Barra di Ricerca -------------------------------------------------------------------------------------------------

      // Ad ogni cambiameno nella barra di ricerca aggiorno il valore Nome
      const handleOnSearch = (string, results) => {
        console.log(string)
        //setDatiCliente({...datiCliente, nome: string})
      }
  
      // Quando viene selezionato un risultato, item contiene tutti i valori ad esso associato,(es. nome ,cognome, indirizzo ecc..)
      // Una volta selezionato si inseriscono questi valori all'interno di datiCliente
      const handleOnSelect = (item) => {
        Cerca(item.cognome,'cognome');
      }
  
      // Vengono creati i risultati da mostrare durante la ricerca  
      const formatResult = (item) => {
        return (
          <div>
            <span style={{ display: 'block', textAlign: 'left' }}>nome: {item.cognome + ' ' +item.nome}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>indirizzo: {item.indirizzo}</span>
          </div>
        )
      }
      
//#endregion

//#region Ricerca ----------------------------------------------------------------------------------------------------------

  //dato = 'Rienzi' -> tipo= 'cognome'
  //dato = '20220' -> tipo = 'data'
  const Cerca = (dato,tipo) =>{

    const data = new FormData();
    data.append('dato', dato); // il dato che voglio cercar
    data.append('tipo', tipo); // il tipo di dato , "cognome" o "anno"
    data.append('tipoDocumento', dati.tipo); // il tipo di documento Preventivo o fattura
    data.append('token', token); 

    var xhttp = new XMLHttpRequest();

    xhttp.open("POST", "./db_gestione/documento_recupero.php", true);
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
           try{
            // quando il server risponde inserisco i dati all'interno di risultati
            setRisultati(JSON.parse(this.responseText));
           }catch(error){console.log(error)}
         }
       }
    // Send request with data
    xhttp.send(data);
  }

  const RecuperoAnni = () =>{
    const data = new FormData();
    data.append('tipo', dati.tipo); // il tipo di documento Preventivo o fattura
    data.append('token', token); 

    var xhttp = new XMLHttpRequest();

    xhttp.open("POST", "./db_gestione/anni_salvati.php", true);
    xhttp.onreadystatechange = function() {
       if (this.readyState == 4 && this.status == 200) {
           try{
            // quando il server risponde inserisco i dati all'interno di risultati
            setAnni(JSON.parse(this.responseText));
           }catch(error){console.log(error)};
         }
       }
    // Send request with data
    xhttp.send(data);
  }

  const CambioAnno = (scelta) =>{
    Cerca(scelta,'anno');
  }
//#endregion -----------------------------------------------------------------------------------------------------------------

//#region Apertura ---------------------------------------------------------------------------------------------------------

const ApriDocumento = (indexDaAprire) => {
    const documento = risultati[indexDaAprire];
    props.output(documento, 'documento',false);
}

//#endregion ---------------------------------------------------------------------------------------------------------------
    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container main_container">
                <h1>BrowserDocumenti</h1>
                <label className="label_input">Nome da cercare</label>
                <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    autoFocus
                    formatResult={formatResult}
                    fuseOptions={{ keys: ["nome", "cognome"] }}
                    resultStringKeyName="cognome"
                    showIcon={true}
                    styling={{
                        borderRadius: "0px",
                      }}
                />

                <div style={{margin:"15px 0px",}}>
                    <label className="label_input">Anno</label>
                    <select className="input_anno" onChange={(e)=>CambioAnno(e.target.value)}>
                        {   anni.length > 0 ?
                            anni.map((i)=>(
                                <option value={i}>{i}</option>
                            )) : null
                        }
                    </select>
                </div>
                {/*-------------Elenco documenti trovati -----------------------------------------*/}
                <div className="Container_risultati">
                    {   risultati.length > 0 ?
                        risultati.map((i,index) => (
                            <div key={'a'+index} className="Scheda_risultato" onClick={()=>ApriDocumento(index)}>
                                <div key={'b'+index} className="scheda_numero">
                                    <h1 className="Quantita">{i.datiFattura['numero']}</h1>
                                    {i.anno}
                                </div>

                                <div key={'c'+index} className='scheda_descrizione_container'>
                                    <h2  className="Descrizione">{i.datiCliente['cognome'] + ' ' + i.datiCliente['nome']}</h2>
                                    <p className="Prezzo_unitario">{i.datiCliente['indirizzo']}</p>
                                </div>

                                <div key={'d'+index} className="scheda_valore">
                                    <h3 className="Prezzo_totale">€ {i.totale}</h3>
                                    {i.inviata == 1 ? 'INVIATA' : null}
                                </div>
                            </div>
                        )) : <h3>Nessun risultato</h3>
                    }
                </div>
                {/*------------------------------------------------------------------------------*/}
                
            

                {
                    // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                
                //<button className='conferma_btn' onClick={()=>props.output('nota', 'nota', false)}>Ok</button>
                }
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='btn_annulla btn_chiudi' onClick={()=>props.output(null, null, false)}>Chiudi</button>
            </div>
        </div>
    )
}

export default BrowserDocumenti;