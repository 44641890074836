import axios from "axios";
import React, {useState, useEffect} from "react";
import './Impostazioni.css';


function Impostazioni(props){
    const dati = props.input.valoreInput;
    const token = props.input.token;

    const [preferenze, setPreferenze] = useState({
        cittaAzienda: dati.cittaAzienda,
        indexBozzaFattura : dati.indexBozzaFattura,
        indexBozzaPreventivo : dati.indexBozzaPreventivo,
    })

    const [bozzeListF, setBozzeListF] = useState([
        {
        id: '0',
        oggetto: '',
        data_creazione: '',
        titolo: 'Nessuna bozza salvata',
        corpo_mail: ''
        },
    ]);
    const [bozzeListP, setBozzeListP] = useState([
        {
        id: '0',
        oggetto: '',
        data_creazione: '',
        titolo: 'Nessuna bozza salvata',
        corpo_mail: ''
        },
    ]);

    useEffect(()=>{
        RecuperoBozze()
    },[])

    useEffect(()=>{
        //console.log(preferenze)
    },[preferenze])

//#region  Salva Impostazioni -----------------------------------------------------------------------------------------------------------------------

    const SalvaPreferenze = () =>{
        const data = new FormData();
        data.set('preferenze', JSON.stringify(preferenze))
        data.set('token', token)
        axios.post('./db_gestione/preferenze_salvataggio.php', data);

        // Faccio chiudere la finestra
        props.output(preferenze, 'impostazioni', false);
    }
//#endregion -----------------------------------------------------------------------------------------------------------------------

//#region Recupero Bozze ------------------------------------------------------------------------------------------------------------------------------
  
    const RecuperoBozze = () =>{
        // for each element nell'array [fattura, preventivo] recupero le bozze.
        ['Fattura','Preventivo'].forEach(tipo => {
            var formData = new FormData();
            formData.append("tipo", tipo);
            formData.set('token', token)
            var xhttp = new XMLHttpRequest();
            xhttp.open("POST", "./db_gestione/mail_recupero.php", true);
            xhttp.onreadystatechange = function() {
               if (this.readyState == 4 && this.status == 200) {
                try{
                 var response = JSON.parse(this.responseText);
                 if(tipo === 'Fattura'){setBozzeListF(response)};
                 if(tipo === 'Preventivo'){setBozzeListP(response)};
                }catch(error){console.log(error)}
               }
            };
            xhttp.send(formData);
        });
    }

//#endregion

    return(
        <div className="Sfondo_blur">
            <div className="Finestra_container">
                <h1>Impostazioni</h1>
                <label className="label_input">Città azienda</label>
                <input type='text'  className='input_text' onChange={(e)=>setPreferenze({...preferenze, cittaAzienda: e.target.value}) } value={preferenze.cittaAzienda? preferenze.cittaAzienda : ''}></input>
                
                <div>
                <label>Email predefinita per Fatture</label>
                    <select name="bozze_Fatture" id="bozze_Fatture" defaultValue={preferenze.indexBozzaFattura? preferenze.indexBozzaFattura : ''} onChange={(e)=>setPreferenze({...preferenze, indexBozzaFattura: e.target.value })}>
                    <option>-- Scegli --</option>    
                    { bozzeListF.map((i, index) => (
                            <option value={index} key={'optf'+index}>{i.titolo}</option>
                          ))
                        }
                </select>
                    <div className="container_anteprima_mail">
                        <p>{ bozzeListF.map((i, index)=> preferenze.indexBozzaFattura == index? i.corpo_mail : null  )} </p>
                    </div>
                </div>

                <div>
                <label>Email predefinita per Preventivi</label>
                    <select name="bozze_Preventivi" id="bozze_Preventivi" defaultValue={preferenze.indexBozzaPreventivo? preferenze.indexBozzaPreventivo : ''} onChange={(e)=>setPreferenze({...preferenze, indexBozzaPreventivo: e.target.value })}>
                    <option>-- Scegli --</option>    
                    { bozzeListP.map((i, index) => (
                            <option value={index} key={'optp'+index}> {i.titolo}</option>
                          ))
                    }
                </select>
                <div className="container_anteprima_mail">
                        <p>{ bozzeListP.map((i, index)=> preferenze.indexBozzaPreventivo == index? i.corpo_mail : null  )} </p>
                    </div>
                </div>
                
                {
                    // sconto restituisce il valore, 'sconto' suggerisce di che tipo di dati si tratta, mentre false viene passato per far scomparire la finestra
                }
                <button className='conferma_btn' onClick={()=>SalvaPreferenze()}>Salva modifiche</button>
                {
                    // Il pulsante annulla passa come output null, null false
                }
                <button className='annulla_btn' onClick={()=>props.output(null, null, false)}>Annulla</button>
            </div>
        </div>
    )
}
export default Impostazioni;