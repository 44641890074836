import React, {useState, useEffect} from "react";
import './Finestra.css';
import DatiCliente from '../Finestre/DatiCliente';
import ProdottiServizi from'../Finestre/ProdottiServizi';
import Sconto from '../Finestre/Sconto';
import Nota from '../Finestre/Nota';
import BrowserDocumenti from "../Finestre/BrowserDocumenti";
import InviaCreaPdf from "../Finestre/InviaCreaPdf";
import MailPredefinitaSalvataggio from "../Finestre/MailPredefinitaSalvataggio";
import Impostazioni from "../Finestre/Impostazioni";
import Precompilato from "../Finestre/Precompilato";

const components = {
    DatiCliente: DatiCliente,
    ProdottiServizi: ProdottiServizi,
    Sconto: Sconto,
    Nota: Nota,
    BrowserDocumenti: BrowserDocumenti,
    InviaCreaPdf: InviaCreaPdf,
    MailPredefinitaSalvataggio: MailPredefinitaSalvataggio,
    Impostazioni: Impostazioni,
    Precompilato: Precompilato,
};

function Finestra(props){

    const FinestraDaAprire = components[props.daAprire];
    ;
    return(
        <div className="Sfondo_blur">     
                    <FinestraDaAprire input={props.input} output={(dati,tipo,stato)=>props.output(dati, tipo, stato)}/>    
        </div>
    )
}
export default Finestra;