import './App.css';
import React, {useState, useEffect} from "react";
import Login from './Finestre/Login';
import Applicazione from './Applicazione';

function App() {
  
  // contiene il token di autorizzazione da passare quando si richiedono i dati 
  const [token, setToken] =useState('');

  // visualizzazione finestra login
  const [loginBox, setLoginBox] = useState(true);

  const [AppBox, setAppBox] = useState(false);

  // quando viene memorizzato un token (diverso da '') allora faccio scomparire il box e faccio apparire l'applicazione
  useEffect(()=>{
    if(token !== ''){
      setLoginBox(false);
      setAppBox(true);
    }
  },[token])


//#region HTML ----------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div className="App" aria-hidden="true">
    {loginBox ? <Login token={(e)=> setToken(e)}></Login> : null}
    {AppBox ? <Applicazione token={token}></Applicazione>:null}
    </div>
  );
//#endregion
}

export default App;
